<template>
  <div class="news_banner">
    <div class="title">魔筷新闻</div>
    <div class="desc">提供魔筷一手资讯，助您了解行业动态</div>
    <div class="icon" @mouseout="imgNone">
      <div class="ks" @mouseover="hoverImg(1)">
        <img :src="ks" alt="" class="icon_img" />
        <div class="ks_photo" v-show="isShow == 1">
          <img
            src="https://mktv-in-cdn.mockuai.com/16353923810581202.png"
            alt=""
          />
        </div>
      </div>
      <div class="wx" @mouseover="hoverImg(2)">
        <img :src="wx" alt="" class="icon_img" />
        <div class="ks_photo" v-show="isShow == 2">
          <img
            src="https://mktv-in-cdn.mockuai.com/16354098648923508.png"
            alt=""
          />
        </div>
      </div>
      <div class="wb" @mouseover="hoverImg(3)">
        <a href="https://weibo.com/u/5602329576?is_all=1" target="_blank">
          <img :src="wb" alt="" class="icon_img" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ks, wx, wb } from "@/static/base64";
export default {
  data() {
    return {
      ks: ks,
      wx: wx,
      wb: wb,
      isShow: "",
    };
  },
  methods: {
    hoverImg(index) {
      this.isShow = index;
    },
    imgNone() {
      this.isShow = "";
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/style/common.less";

.news_banner {
  box-sizing: border-box;
  .pxToVw(height, 680);
  .pxToVw(padding-top, 181);
  background-color: #00092a;
  background-image: url(https://mktv-in-cdn.mockuai.com/16352393481679355.png);
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
  & .title {
    margin: 0 auto;
    .pxToVw(font-size, 80);
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
  & .desc {
    .pxToVw(font-size, 28);
    .pxToVw(margin-top, 28);
    .pxToVw(line-height, 40);
    .pxToVw(letter-spacing, 1);
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
  & .icon {
    .pxToVw(margin-top, 71);
    display: flex;
    justify-content: center;
    .ks,
    .wx,
    .wb {
      .pxToVw(width, 36);
      .pxToVw(height, 36);
      position: relative;
      .icon_img {
        width: 100%;
        height: 100%;
      }
    }
    .ks_photo {
      position: absolute;
      .pxToVw(width, 140);
      .pxToVw(height, 140);
      .pxToVw(left, -52);

      img {
        width: 100%;
        height: 100%;
      }
    }
    .wx {
      .pxToVw(margin-left, 34);
      .pxToVw(margin-right, 34);
    }
  }
}
</style>
