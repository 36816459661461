<template>
  <div class="article">
    <div class="banner">
      <news-banner></news-banner>
    </div>
    <div class="main">
      <news-container :articleList="articleList"></news-container>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :total="count"
        :page-size="18"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
// 新资讯 中心
import axios from "axios";
import NewsBanner from "@/components/news/NewsBanner";
import NewsContainer from "@/components/news/NewsContainer";

export default {
  head: {
    title: '魔筷资讯-新闻动态-资讯新闻-直播动态-魔筷科技',
    meta: [
      {
        name: 'Keywords',
        content:
          '直播资讯,直播电商热点,电商资讯,直播带货资讯',
      },
      {
        name: 'Description',
        content:
          '【魔筷科技-直播电商服务机构】作为电商综合服务企业,提供魔筷资讯动态及直播电商行业干货热点,助您了解直播带货行业。',
      },
    ],
  },
  // needPrefetch: true,
  needSerialize: true,
  data() {
    return {
      currentPage: 1,
      articleList: [],
      count: 0,
      tags: "",
    };
  },

  async created() {
    await this.getArticleList();
  },

  methods: {
    async getArticleList() {
      try {
        const params = {
          per_page: 18,
          orderby: "date",
          page: this.currentPage,
          categories: 9,
        };
        const res = await axios.get(
          "https://wp.mockuai.com/wp-json/wp/v2/posts?per_page=18&orderby=date",
          { params }
        );
        // console.log('获取的新闻列表', res)
        if (res.data) {
          this.articleList = res.data;
          this.articleList.forEach((v, i) => {
            this.articleList[i].excerpt.rendered = this.articleList[
              i
            ].excerpt.rendered.replace(/<[^>]+>/g, "");
          });
        }
        let articleCountApi = `https://www.mockuai.com/wp-json/wp/v2/categories?include=9`;
        const countRes = await axios.get(articleCountApi);

        if (countRes.data) {
          this.count = countRes.data[0].count;
        }
      } catch (error) {
        console.error("获取新闻列表失败");
      }
    },

    onCurrentChange(page) {
      console.log(page, this.count);
      this.currentPage = page;
      this.getArticleList();
    },
  },
  components: {
    NewsBanner,
    NewsContainer,
  },
};
</script>

<style lang="less" scoped>
@import "../style/common.less";

.article {
  padding-top: 90px;
  .pagination {
    text-align: center;
    margin: 61px 0 80px 0;
  }
  /deep/ .el-pagination.is-background .el-pager {
    li:hover {
      color: #ff5f23;
    }
    li.active {
      background: #ff6600;
      color: #fff;
    }
  }
  /deep/ button {
    .pxToVw(width,40);
    .pxToVw(height,40);
  }
  /deep/ .el-pager .number {
    .pxToVw(width,40);
    .pxToVw(height,40);
    .pxToVw(line-height,40);
    .pxToVw(font-size,18);
  }
  /deep/ .el-icon {
    .pxToVw(width,40);
    .pxToVw(height,40);
    .pxToVw(line-height,40);
  }
}
@media screen and (min-width: 1440px) {
  .pagination {
    /deep/ button {
      width: 40px;
      height: 40px;
    }
    /deep/ .el-pager .number {
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
    }
    /deep/ .el-icon {
      width: 40px;
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>
