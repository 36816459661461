<template>
  <div class="report-body">
    <ul class="report-list">
      <li
        :class="['report', { mrNone: (index + 1) % 3 === 0 ? 'mrNone' : '' }]"
        v-for="(item, index) in articleList"
        :key="index"
      >
        <div class="news_contaniner">
          <a :href="`/article/${item.id}`" target="_blank">
            <div class="img-wrap" ref="img-wrap">
              <div class="label_box">
                <span class="icon">
                  <img :src="ReportLabel" alt="" />
                </span>
                <span class="info">{{ item.category_name }}</span>
              </div>
              <div class="thumb_img">
                <img :src="item.thumb_image" alt="" />
              </div>
            </div>
            <div class="word-wrap">
              <span class="title">{{ item.title.rendered }}</span>
              <div class="desc" v-html="item.excerpt.rendered"></div>
            </div>
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { ReportLabel } from "@/static/base64.js";
export default {
  props: ["articleList"],
  data() {
    return {
      ReportLabel: ReportLabel,
      ReportData: [],
    };
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
@import "@/style/common.less";

.report-body {
  .pxToVw(padding-left, 240);
  .pxToVw(padding-right, 240);
  .report-list {
    display: flex;
    flex-wrap: wrap;
    transition: all 0.5;
    width: 100%;
    box-sizing: border-box;

    .report {
      .pxToVw(margin-top, 80);
      .pxToVw(margin-right,40);
    }

    .mrNone {
      .pxToVw(margin-right, 0);
    }
  }
  .report:hover .news_contaniner {
    transform: translateY(-10px);
    box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
  }
  .title:hover {
    color: #2e6fed !important;
  }
  & .news_contaniner {
    .pxToVw(width, 440);
    .pxToVw(height, 400);
    .pxToVw(border-radius, 16);
    box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    overflow: hidden;
    .img-wrap {
      .pxToVw(width, 440);
      .pxToVw(height, 253);
      position: relative;
      .thumb_img {
        width: 100%;
        .pxToVw(height, 253);

        img {
          display: block;
          width: 100%;
          .pxToVw(height, 253);
          object-fit: cover;
        }
      }
      .label_box {
        position: absolute;
        .pxToVw(left, 30);
        .pxToVw(bottom, 13);
        display: flex;
        align-items: center;
      }
      .info {
        color: #ffffff;
        .pxToVw(font-size, 14);
      }
      .icon {
        margin-right: 5px;
        img {
          .pxToVw(width, 12);
          display: block;
          .pxToVw(height, 14);
        }
      }
    }
    .word-wrap {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .pxToVw(padding-top, 25);
      .pxToVw(padding-left, 30);
      .pxToVw(padding-right, 30);
      .pxToVw(padding-bottom, 25);
      .title {
        .pxToVw(width, 366);
        .pxToVw(font-size, 24);
        .pxToVw(line-height, 33);
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .desc {
        .pxToVw(width, 390);
        .pxToVw(font-size, 16);
        line-height: 1.5;
        .pxToVw(margin-top, 10);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}
a {
  text-decoration: none;
}
/deep/ a {
  text-decoration: none;
  color: #666666;
}
</style>
